import axios from 'axios';
import { SEARCH_MEDS_API_BASE_URL } from 'shared/constants';

const api = axios.create({
  baseURL: SEARCH_MEDS_API_BASE_URL,
});

export default class SearchMedsAPIService {
  static async getData(url: string, params?: object): Promise<any> {
    const response = await api.get(url, { params });
    return response;
  }

  static async postData(url: string, params?: object): Promise<any> {
    const response = await api.post(url, { ...params });
    return response;
  }

  static async deleteData(url: string): Promise<any> {
    const response = await api.delete(url);
    return response;
  }

  static async updateData(url: string, params?: object): Promise<any> {
    const response = await api.put(url, { ...params });
    return response;
  }
}
