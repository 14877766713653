import styled from 'styled-components';
import { colors } from 'shared/theme';

export const DiscountCardListContainer = styled.div`
  .ui.orange.button,
  .ui.orange.buttons .button {
    color: #fff;
    text-shadow: none;
    width: 241px;
    height: 70px;
    background: ${colors.mainOrange};
    border-radius: 6px;
  }

  .get-card-btn {
    background: #1c5cfd;
    border-radius: 16px !important;
    text-transform: uppercase;
    width: 140px !important;
  }

  span {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: ${colors.basicText};
    opacity: 0.5;
  }

  .ui.list {
    .item {
      background: #ffffff;
      border: 1px solid #e3ebfe;
      box-shadow: 0px 56px 80px -40px rgba(43, 43, 43, 0.15);
      border-radius: 8px;
      padding: 20px;
      margin: 20px 0px;
    }
  }

  .ui.header {
    font-family: Ubuntu !important;
    font-style: normal;
    font-weight: bold !important;
    color: ${colors.basicText} !important;
  }

  h6.ui.header {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
  }

  h1.ui.header {
    font-size: 28px;
  }

  h2.ui.header {
    font-size: 20px;
  }

  img.ui.small.image {
    height: 20px;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    .ui.orange.button,
    .ui.orange.buttons .button {
      width: 100%;
    }
  }
`;
